import {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useContext,
} from 'react';
import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';
import { Heading } from '~/components/ui';
import Image from 'next/image';
import useUser from '~/lib/user';
import { toast } from 'react-hot-toast';
import useWebSocket, {
  ReadyState,
  resetGlobalState,
} from 'react-use-websocket';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import useSWR from 'swr';
import { currency } from '~/lib/format';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import askLogo from '~/public/asksumo.svg';
import askAvatar from '~/public/asksumo-avatar.svg';
import { Button } from '@appsumo/dorado-react';
import { useCart } from '~/lib/cart';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import { fetchJson } from '~/lib/fetch';
import {
  faThumbsUp as faSolidThumbsUp,
  faThumbsDown as faSolidThumbsDown,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from '~/components/global/Link';
import { useInterval } from 'usehooks-ts';

const SkuCard = dynamic(() =>
  import('~/components/sku').then((mod) => mod.SkuCard),
);

import type { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { ASKSUMO_COUPON } from '~/lib/util/constants';
import { GlobalContext } from '~/contexts/global';

const ReactMarkdown = dynamic<ReactMarkdownOptions>(
  import('react-markdown') as any,
);

const DealDownloads = dynamic(() =>
  import('~/components/product').then((mod) => mod.DealDownloads),
);

const DealRating = dynamic(() =>
  import('~/components/product').then((mod) => mod.DealRating),
);

const websocketUrl = `${process.env.NEXT_PUBLIC_DJANGO_PUBLIC_URL?.replace(
  'http',
  'ws',
)}/ws/asksumo/chat`;
//const websocketUrl = 'wss://stage-ci6.appsumo.com/ws/asksumo/chat';

const couponCode = ASKSUMO_COUPON;

/*
“Never criticize anyone making an effort to create.” — Noah Kagan
“Easy way to find your calling. Ask yourself: ‘Would I do this for free?’ If not, move on.” — Noah Kagan
“If you want a customer, ASK for it. Success is a game of asking.” — Noah Kagan
“Marketing has always been about the same thing — who your customers are and where they are.”
— Noah Kagan
“Tacos are a gateway drug to success.” — Noah Kagan

 9. "The best way to predict the future is to create it." - Peter Drucker, Austrian-born American management consultant and author.

 10. "If you don't build your dreams, someone else will hire you to build theirs." - Tony Gaskins, American motivational speaker and author.

 11. "Entrepreneurship is about creating something new, something that doesn't exist." - Ben Horowitz, American entrepreneur and investor.

 12. "Productivity is never an accident. It is always the result of a commitment to excellence, intelligent planning, and focused effort." - Paul J. Meyer, American businessman and author.

 13. "I'm convinced that about half of what separates successful entrepreneurs from the non-successful ones is pure perseverance." - Steve Jobs, co-founder of Apple Inc.

 14. "Entrepreneurship is living a few years of your life like most people won't so that you can spend the rest of your life like most people can't." - Anonymous

 15. "Productivity is not just about getting things done. It's about getting the right things done." - Chris Bailey, Canadian author and productivity expert.

 16. "The biggest risk is not taking any risk. In a world that is changing really quickly, the only strategy that is guaranteed to fail is not taking risks." - Mark Zuckerberg, co-founder of Facebook.

 17. "Entrepreneurship is the art of finding profitable solutions to problems." - Kevin Harrington, American entrepreneur and television personality.

 18. "Productivity is being able to do things that you were never able to do before." - Franz Kafka, Czech writer.

 19. "The only way to do great work is to love what you do." - Steve Jobs, co-founder of Apple Inc.

 20. "Entrepreneurship is not about having an idea. It's about having an idea and then doing something about it." - Nolan Bushnell, American engineer and entrepreneur.
*/

const quotes = [
  {
    quote:
      "If you're not embarrassed by the first version of your product, you've launched too late.",
    author: 'Reid Hoffman, co-founder of LinkedIn',
  },
  {
    quote: 'The road to success is always under construction.',
    author: 'Lily Tomlin, American actress and comedian',
  },
  {
    quote:
      'Productivity is never an accident. It is always the result of a commitment to excellence, intelligent planning, and focused effort.',
    author: 'Paul J. Meyer, American businessman and author',
  },
  {
    quote:
      'Entrepreneurship is the ability to create something out of nothing and sell it for a profit.',
    author: 'Damon John, American entrepreneur and investor',
  },
  {
    quote: "I'm not a businessman, I'm a business, man!",
    author: 'Jay-Z, American rapper and entrepreneur',
  },
  {
    quote: 'The way to get started is to quit talking and begin doing.',
    author: 'Walt Disney, American entrepreneur and animator',
  },
  {
    quote:
      "Entrepreneurship is living a few years of your life like most people won't so that you can spend the rest of your life like most people can't.",
    author: 'Warren G. Tracy, American businessman and author',
  },
  {
    quote:
      'Productivity is being able to do things that you were never able to do before.',
    author: 'Franz Kafka, Czech writer',
  },
];

const uuid = () => {
  // make sure Blob is defined to prevent build error
  if (typeof Blob === 'undefined') {
    return '';
  }

  const uuidObj = URL.createObjectURL(new Blob());
  const uuidStr = uuidObj.toString().substr(-36);
  // clean up to avoid memory leak
  URL.revokeObjectURL(uuidObj);
  return uuidStr;
};

function productLink(
  link: string,
  isNewBuyer: boolean,
  allowAsksumoDiscount: boolean,
) {
  const linkWithCoupon = `${link}${
    isNewBuyer && allowAsksumoDiscount ? `?coupon=${couponCode}` : ''
  }`;

  if (link.startsWith('http')) {
    const url = new URL(link);

    if (
      url.hostname.endsWith('appsumo.com') &&
      url.pathname.startsWith('/products/')
    ) {
      return linkWithCoupon;
    }
  } else if (link.startsWith('/products/')) {
    return linkWithCoupon;
  }

  return link;
}

const ClientMessage = forwardRef<HTMLDivElement, any>(
  ({ message }: { message: string }, ref) => {
    const { user } = useUser();

    return (
      <div className="m-2 flex gap-x-4" ref={ref}>
        <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-dollar max-sm:hidden">
          {user?.avatar_small ? (
            <Image
              src={`${user?.avatar_small}`}
              alt={user?.email}
              className="h-8 w-8 rounded-full"
              unoptimized
              width={32}
              height={32}
            />
          ) : (
            <FontAwesomeIcon icon={faUser} className="text-black-pearl" />
          )}
        </div>
        <div className="grow rounded-lg rounded-tl-none bg-asksumo p-4">
          {message}
        </div>
      </div>
    );
  },
);

ClientMessage.displayName = 'ClientMessage';

function ServerDeal({
  url,
  onLoad,
  primary,
  isNewBuyer,
}: {
  url: string;
  isNewBuyer: boolean;
  primary: boolean;
  onLoad: (dealId: number) => void;
}) {
  const {
    campaigns: { allowAsksumoDiscount },
  } = useContext(GlobalContext);
  const slug = url.split('/').filter(Boolean).pop();
  const apiUrl = `${process.env.NEXT_PUBLIC_DJANGO_PUBLIC_URL}/api/v2/deals/esbrowse/?profile{slugs}=${slug}&status=all`;
  const { data: { deals } = [] } = useSWR(apiUrl, fetchJson, {
    // A change was made to not use the default SWR re-evaluate props
    revalidateIfStale: true,
    revalidateOnReconnect: true,
  });
  const deal = deals?.length > 0 ? deals[0] : null;

  useEffect(() => {
    const dealId = deal?.id;
    if (dealId) {
      onLoad(dealId);
    }
  }, [deal?.id, onLoad]);

  if (primary) {
    return (
      <>
        {deal && (
          <div className="w-72">
            <SkuCard
              target="_blank"
              deal={{
                ...deal,
                get_absolute_url:
                  isNewBuyer && allowAsksumoDiscount
                    ? `${deal.get_absolute_url}?coupon=${couponCode}`
                    : deal.get_absolute_url,
              }}
              imgWidth={372}
              imgHeight={209}
            />
          </div>
        )}
      </>
    );
  }

  if (!deal) return <></>;

  const productHref: string = productLink(
    deal.get_absolute_url,
    isNewBuyer,
    allowAsksumoDiscount,
  );

  return (
    <>
      <div
        className="relative w-full rounded-md border border-sundog bg-white p-4 shadow max-sm:max-w-[calc(100vw-48px)] md:w-96"
        data-deal-id={deal.id}
      >
        <Link
          href={productHref}
          target="_blank"
          className="absolute h-full w-full text-[0px] after:pointer-events-auto after:absolute after:inset-0 after:z-[1] after:content-['']"
        >
          <span className="sr-only">{deal.public_name}</span>
        </Link>
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-2">
            <p className="grow line-clamp-2">{deal?.card_description}</p>
            <a
              href={productHref}
              className="text-bolt"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="w-20 shrink-0">
              <Image
                src={`${deal.media_url}?width=160&height=107&aspect_ratio=3:2`}
                alt={deal.public_name}
                width={80}
                height={53}
                unoptimized
                className="aspect-sku-card w-20 rounded"
              />
            </div>
            <div className="flex w-full flex-col gap-y-1 overflow-hidden">
              <a
                href={productHref}
                target="_blank"
                className="max-w-xs truncate font-bold text-bolt"
                rel="noopener noreferrer"
              >
                {deal.public_name}
              </a>
              <div className="flex flex-col justify-center gap-y-2">
                <div className="flex gap-x-1">
                  <strong className="font-medium">
                    {deal.price === 0 && 'FREE'}
                    {deal.price > 0 && currency(deal.price)}
                  </strong>
                  {deal.original_price > 0 && (
                    <span className="text-sm text-grace line-through">
                      {currency(deal.original_price)}
                    </span>
                  )}
                </div>
                {deal.is_free && (
                  <DealDownloads
                    downloads={deal.freebie_downloads_total}
                    href={productHref}
                    target="_blank"
                  />
                )}
                {!deal.is_free && (
                  <DealRating
                    averageRating={deal.deal_review?.average_rating}
                    reviewCount={deal.deal_review?.review_count}
                    target="_blank"
                    href={`${productHref}#reviews`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

async function promiseMapSeries(array: any, iterator: any) {
  const length = array.length;
  const results = new Array(length);

  for (let i = 0; i < length; ++i) {
    results[i] = await iterator(array[i], i, array);
  }

  return results;
}

function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const ServerMessage = forwardRef<HTMLDivElement, any>(
  (
    {
      message,
      id,
      conversationId,
      finished,
      isNewBuyer,
    }: {
      message: string | null;
      id: number;
      conversationId: string;
      finished: boolean;
      isNewBuyer: boolean;
    },
    ref,
  ) => {
    const [quoteIndex, setQuoteIndex] = useState<number>(
      randomInt(0, quotes.length - 1),
    );
    const [hasHelpful, setHasHelpful] = useState<boolean | null>(null);
    const { lineItemAdd } = useCart();
    const {
      campaigns: { allowAsksumoDiscount },
    } = useContext(GlobalContext);
    const [dealIds, setDealIds] = useState<number[]>([]);
    const markdownLinks = useMemo(() => {
      const regexMdLinks = /\[([^[]+)\]\(([^)]+)\)/gm;
      const singleMatch = /\[([^[]+)\]\((.*)\)/;
      const links = message
        ?.match(regexMdLinks)
        ?.map((match) => singleMatch.exec(match))
        .filter((match) => {
          const uri = match?.length === 3 ? new URL(match[2]) : null;
          return (
            uri?.hostname.endsWith('appsumo.com') &&
            uri?.pathname.startsWith('/products/')
          );
        })
        .map((match) => (match && match.length > 1 ? match[2] : false))
        .filter(Boolean);
      return [...new Set(links)];
    }, [message]) as string[];

    useInterval(() => {
      setQuoteIndex(randomInt(0, quotes.length - 1));
    }, 5000);

    const isSingleDealLoaded = useMemo(() => dealIds.length === 1, [dealIds]);

    const handleDealLoaded = useCallback(
      (dealId: number) => {
        setDealIds((prev) => [...new Set(prev.concat(dealId))]);
      },
      [setDealIds],
    );

    const deals = useMemo(
      () =>
        markdownLinks.map((link) => (
          <ServerDeal
            key={link}
            url={link}
            isNewBuyer={isNewBuyer}
            primary={isSingleDealLoaded}
            onLoad={handleDealLoaded}
          />
        )),
      [markdownLinks, isNewBuyer, isSingleDealLoaded, handleDealLoaded],
    );

    const addDealsToCart = useCallback(() => {
      promiseMapSeries(dealIds, (dealId: number) =>
        lineItemAdd(
          dealId,
          null,
          isNewBuyer && allowAsksumoDiscount ? couponCode : null,
        ),
      )
        .then(() =>
          toast.success(
            dealIds.length > 1 ? 'Deals added to cart' : 'Deal added to cart',
          ),
        )
        .catch(() =>
          toast.error(
            dealIds.length > 1
              ? 'Failed to add deals to cart'
              : 'Failed to add deal to cart',
          ),
        );
    }, [dealIds, lineItemAdd, isNewBuyer, allowAsksumoDiscount]);

    const handleHelpful = useCallback(
      (helpful: Boolean) => {
        const url = '/api/asksumo/feedback/';
        const data = {
          conversation_uuid: conversationId,
          conversation_index: id,
          helpful: String(helpful),
        };

        fetchJson(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          include: 'credentials',
          body: JSON.stringify(data),
        })
          .then(() => setHasHelpful(helpful ? true : false))
          .catch((e) => console.log('EX:', e));
      },
      [conversationId, id, setHasHelpful],
    );

    const quote = quotes[quoteIndex];

    return (
      <div className="m-2 flex gap-x-4" ref={ref}>
        <div className="flex h-8 w-8 shrink-0 items-center justify-center max-sm:hidden">
          <Image
            src={askAvatar}
            alt="AskSumo"
            className="h-8 w-8"
            unoptimized
            width={32}
            height={32}
          />
        </div>

        <div className="grow max-sm:flex max-sm:flex-col md:px-4">
          {deals && deals.length > 0 && (
            <div className="mb-4 flex flex-col items-center gap-y-4 text-sm text-black-pearl empty:hidden max-sm:order-1 max-sm:mt-4 md:float-right md:ml-4">
              {deals}
              {dealIds.length > 0 && (
                <div>
                  <Button onClick={addDealsToCart}>
                    Buy {dealIds.length === 1 ? 'this deal' : 'these deals'}
                    {isNewBuyer && allowAsksumoDiscount && ', get 10% off!'}
                  </Button>
                </div>
              )}
            </div>
          )}
          <div className="space-y-4">
            {message !== null ? (
              <ReactMarkdown
                components={{
                  a: ({ children, href, ...props }) => (
                    <a
                      {...props}
                      href={productLink(
                        href as string,
                        isNewBuyer,
                        allowAsksumoDiscount,
                      )}
                      className="text-blue-500 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {children}
                    </a>
                  ),
                  ul: ({ children, ...props }) => (
                    <ul
                      {...props}
                      className="ml-4 list-disc space-y-4 [&:has(>:nth-child(6))]:space-y-0"
                    >
                      {children}
                    </ul>
                  ),
                  ol: ({ children, ...props }) => (
                    <ol
                      {...props}
                      className="ml-4 list-decimal space-y-4 [&:has(>:nth-child(6))]:space-y-0"
                    >
                      {children}
                    </ol>
                  ),
                }}
              >
                {message}
              </ReactMarkdown>
            ) : (
              <div className="flex flex-col gap-x-4">
                <div className="flex items-center gap-x-2">
                  <span className="font-semibold text-[#069A2F]">Thinking</span>
                  <div className="flex h-5 items-center gap-x-1">
                    <div className="inline-block h-2 w-2 animate-typing rounded-full animation-delay-200"></div>
                    <div className="inline-block h-2 w-2 animate-typing rounded-full animation-delay-300"></div>
                    <div className="inline-block h-2 w-2 animate-typing rounded-full animation-delay-400"></div>
                  </div>
                </div>
                <div className="text-grace">
                  &ldquo;{quote.quote}&rdquo; &mdash; {quote.author}
                </div>
              </div>
            )}
            {finished === true && message !== null && id !== -1 && (
              <div className="flex gap-x-4 text-lg">
                <button onClick={() => handleHelpful(true)}>
                  <span className="sr-only">Helpful</span>
                  <FontAwesomeIcon
                    icon={hasHelpful === true ? faSolidThumbsUp : faThumbsUp}
                  />
                </button>
                <button onClick={() => handleHelpful(false)}>
                  <span className="sr-only">Not helpful</span>
                  <FontAwesomeIcon
                    icon={
                      hasHelpful === false ? faSolidThumbsDown : faThumbsDown
                    }
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);

ServerMessage.displayName = 'ServerMessage';

const examples = [
  'What are some alternatives to Mailchimp for creating an email list and sending newsletters?',
  'I run a YouTube channel and need a tool that can help me with some social media pre-post-work.',
  'I need a tool that helps me host webinars.',
  'I need an AI writer to help me generate content.',
];

function Examples({ onSuggest }: { onSuggest: (example: string) => void }) {
  const exampleElements = useMemo(
    () =>
      examples.map((example, index) => (
        <button
          key={index}
          className={`relative rounded border border-sundog bg-white px-4 py-6 pb-12 text-left shadow-md outline-none hover:border-black-pearl [&:nth-child(3)]:max-lg:hidden`}
          onClick={() => onSuggest(example)}
        >
          {example}
          <div className="absolute bottom-4 right-4">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </button>
      )),
    [onSuggest],
  );

  return (
    <div className="grid gap-4 pt-4 text-black-pearl sm:max-md:grid-cols-3 md:max-lg:grid-cols-3 max-sm:grid-cols-1 lg:grid-cols-4">
      {exampleElements}
    </div>
  );
}

function AskHeader() {
  return (
    <>
      <Image src={askLogo} alt="AskSumo" width={117} height={40} />
      <Heading.H1 className="!text-[26px] md:!text-[36px]">
        Search & save on software
      </Heading.H1>
      <div className="mb-3 text-lg">
        Chat with AskSumo to find cost-effective software alternatives.
      </div>
    </>
  );
}

function Disclaimer() {
  return (
    <p className="max-w-3xl text-center text-sm text-grace">
      AskSumo is currently in beta. Our goal is to build an AI that connects you
      with the best deals for your business. Your feedback will help us improve.
    </p>
  );
}

function NetworkError() {
  return (
    <div className="flex gap-x-4 rounded bg-lemon/50 p-4 text-left">
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        className="text-3xl text-black-pearl"
      />
      <div>
        <strong>Network error</strong>
        <p>
          We encountered an error, but don&apos;t worry we can try this again.
          Please refresh your browser and if the problem persists, reach out to{' '}
          <a href="mailto:support@appsumo.com" className="text-bolt underline">
            support@appsumo.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}

function ChatPageFooter() {
  return (
    <div className="bg-black-pearl px-4 text-white">
      <div className="mx-auto grid w-full max-w-7xl  grid-cols-1 items-center justify-center border-b border-b-grace bg-black-pearl py-4 text-white sm:grid-cols-3 sm:py-8">
        <div className="border-r-grace py-4 text-center sm:border-r">
          Trusted by over <strong>1 million entrepreneurs</strong>
        </div>
        <div className="border-r-grace py-4 text-center sm:border-r">
          <strong>60-day</strong> money-back guarantee
        </div>
        <div className="py-4 text-center">
          <strong>World-class</strong> customer support
        </div>
      </div>
    </div>
  );
}

function UnlockBanner() {
  return (
    <div className="mx-auto text-center">
      <div className="inline-block rounded border border-dashed border-dorado bg-[#FFF6DE] p-2 text-black-pearl">
        🤑 Unlock <strong>10% off</strong> your first purchase by trying AskSumo
        today.
      </div>
    </div>
  );
}

type ChatMessage = {
  id?: number | null;
  who: string;
  message: string;
  finished?: boolean;
};

type WebsocketMessage = {
  message_id: number;
  message: string;
  message_finished: boolean;
};

function findHighestServerFinishedId(messages: ChatMessage[]) {
  return messages.reduce((highestId, message) => {
    if (message.who === 'server' && message.finished === true) {
      return Math.max(
        highestId,
        message.id === null || message.id === undefined ? -1 : message.id,
      );
    }
    return highestId;
  }, -1);
}

function findLatestClientMessage(messages: ChatMessage[]) {
  return messages.reduceRight((latestMessage: ChatMessage | null, message) => {
    if (message.who === 'client' && latestMessage === null) {
      return message;
    }
    return latestMessage;
  }, null);
}

export default function ChatPage() {
  const { user, isLoading: isLoadingUser } = useUser();
  const [conversationId, setConversationId] = useState<string>(uuid());
  const router = useRouter();
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const [question, setQuestion] = useState('');
  const [askedQuestion, setAskedQuestion] = useState(false);
  const [messageHistory, setMessageHistory] = useState<ChatMessage[]>([]);

  const { lastJsonMessage, sendJsonMessage, readyState, getWebSocket } =
    useWebSocket(`${websocketUrl}/${conversationId}/`, {
      onOpen: () => {
        // Define a recursive function called `findAndResend` that takes an index as its parameter
        const findAndResend = (index: number) => {
          // Find the index of the first message with `who` property equal to "server"
          // and `id` property equal to null, starting from the specified index
          const i = messageHistory.findIndex(
            (msg, pos) =>
              pos > index && msg.who === 'server' && msg.id === null,
          );

          // Check if the index is valid (i.e., a message has been found)
          if (i > -1) {
            // If there's a previous message, resend it
            if (i > 0) {
              sendJsonMessage({ message: messageHistory[i - 1].message });
            }
            // Continue the search for pending messages from the next index
            findAndResend(i + 1);
          }
        };

        // Start the search for pending messages from index 0
        findAndResend(0);
      },
      onClose: () => console.log('Chat closed'),
      retryOnError: true,
      shouldReconnect: () => true,
      reconnectAttempts: 5,
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    });

  /*
  const connectionStatus = {
    [ReadyState.CONNECTING]: `Connecting`,
    [ReadyState.OPEN]: `Connected`,
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
  */

  const {
    campaigns: { allowAsksumoDiscount },
  } = useContext(GlobalContext);

  const isConnected = useMemo(
    () => readyState === ReadyState.OPEN,
    [readyState],
  );
  const isClosed = useMemo(
    () => readyState === ReadyState.CLOSED,
    [readyState],
  );

  const scrollIntoView = useCallback(() => {
    const nav = document.getElementsByClassName('border-navbar')?.[0];
    const pos = bottomRef.current?.getBoundingClientRect();
    if (nav && pos) {
      window.scrollTo({
        left: 0,
        top: pos.top + window.scrollY - nav.clientHeight - 20,
        behavior: 'smooth',
      });
    }
  }, []);

  const askQuestion = useCallback(
    (question: string) => {
      if (!isConnected || !question) {
        return;
      }

      // push a null navigation event the first time a question is asked so we can back from it
      if (!askedQuestion) {
        const url = new URL(window.location.href);
        url.searchParams.set('question', question);
        router.push(url);
      }

      setMessageHistory((prev) =>
        prev.concat(
          {
            message: question,
            who: 'client',
            finished: true,
          },
          {
            id: null,
            message: '',
            who: 'server',
            finished: false,
          },
        ),
      );
      sendJsonMessage({ message: question });
      setQuestion('');
      setAskedQuestion(true);
    },
    [sendJsonMessage, askedQuestion, isConnected, router],
  );

  useEffect(() => {
    if (
      readyState === ReadyState.OPEN &&
      router.query.question &&
      askedQuestion === false
    ) {
      askQuestion(router.query.question as string);
    }
  }, [router.query.question, askQuestion, readyState, askedQuestion]);

  const handleSubmitQuestion = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      askQuestion(question);
    },
    [askQuestion, question],
  );

  const handleSuggest = useCallback(
    (question: string) => {
      askQuestion(question);
    },
    [askQuestion],
  );

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const id = (lastJsonMessage as WebsocketMessage).message_id;
      const finished = (lastJsonMessage as WebsocketMessage).message_finished;
      const messageText = (lastJsonMessage as WebsocketMessage).message;
      //console.log('Received:', lastJsonMessage, bottomRef?.current);
      setMessageHistory((prev) => {
        // check for bogus server resets
        const highestId = findHighestServerFinishedId(prev);
        // if the new server reply is lower we are out of sequence so reset message history
        if (-1 < id && id <= highestId) {
          // find the last client message we sent and make it our new state
          const clientMessage = findLatestClientMessage(prev);
          if (clientMessage) {
            prev = [clientMessage];
          } else {
            prev = [];
          }
        }

        // streaming has finished
        const currentIndex = prev.findIndex(
          (item) =>
            item.who === 'server' &&
            ((item.id === id && id !== -1) || item.id === null),
        );
        if (currentIndex === -1 && typeof messageText !== 'undefined') {
          // start of streaming
          const ret = prev.concat({
            message: messageText,
            id,
            who: 'server',
            finished,
          });
          scrollIntoView();
          return ret;
        } else if (currentIndex !== -1) {
          // append message text to current message index
          const ret = JSON.parse(JSON.stringify(prev));
          ret[currentIndex].id = id;
          if (typeof messageText !== 'undefined') {
            ret[currentIndex].message += messageText;
          }
          if (typeof finished !== 'undefined') {
            ret[currentIndex].finished = finished;
            scrollIntoView();
          } else if (id === -1) {
            ret[currentIndex].finished = true;
            scrollIntoView();
          }
          return ret;
        }

        return prev;
      });
    }
  }, [lastJsonMessage, setMessageHistory, scrollIntoView]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // BACK button was pressed
      if (
        url.indexOf(router.pathname) === 0 &&
        askedQuestion &&
        !router.query.question
      ) {
        getWebSocket()?.close();
        setConversationId(uuid());
        resetGlobalState(websocketUrl);
        setMessageHistory([]);
        setQuestion('');
        setAskedQuestion(false);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [askedQuestion, router.events, getWebSocket, router]);

  const AskPrompt = useMemo(() => {
    const handleEnter = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        handleSubmitQuestion(e);
      }
    };

    return (
      <form
        className="flex w-full resize-none items-center rounded-lg border border-grace bg-iceberg px-4 py-2 text-lg"
        onSubmit={handleSubmitQuestion}
      >
        <TextareaAutosize
          className="w-full resize-none bg-iceberg outline-none disabled:cursor-not-allowed"
          style={{ height: 28 }}
          minRows={1}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={handleEnter}
          disabled={!isConnected}
          placeholder={askedQuestion ? '' : "I'm looking for software to...."}
        />
        <button
          className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-dollar/40 disabled:cursor-not-allowed"
          disabled={!isConnected || !question}
        >
          <span className="sr-only">Send</span>
          <FontAwesomeIcon icon={faArrowRight} className="text-black-pearl" />
        </button>
      </form>
    );
  }, [isConnected, question, handleSubmitQuestion, askedQuestion]);

  const isNewBuyer = useMemo(
    () => !((user?.number_of_purchases || 0) > 0),
    [user],
  );

  return (
    <>
      <NextSeo
        title={'AskSumo | Get help with your business'}
        description={
          'Search the best deals on software, courses, and tools for entrepreneurs with AppSumo. Save big on lifetime deals and grow your business today!'
        }
      />
      <div
        className={`relative before:absolute before:right-0 before:top-0 before:z-[-1] before:h-[616px] before:w-[692px] before:bg-[url('~/public/chat-bg-top-right.svg')] before:content-[''] after:absolute after:bottom-0 after:left-0 after:z-[-1] after:h-[509px] after:w-[370px] after:bg-[url('~/public/chat-bg-bottom-left.svg')] after:content-[''] before:max-sm:hidden after:max-sm:content-none`}
      >
        <div className="mx-auto flex w-full max-w-7xl grow flex-col items-center gap-y-8 px-4 py-6 md:px-8 md:py-16">
          <div className="flex flex-col items-center gap-y-2 text-center">
            <AskHeader />
            {!askedQuestion && (
              <div className="w-full max-w-xl space-y-4">
                {AskPrompt}
                {!isLoadingUser && isNewBuyer && allowAsksumoDiscount && (
                  <UnlockBanner />
                )}
                {isClosed && <NetworkError />}
              </div>
            )}
          </div>
          {!askedQuestion && <Examples onSuggest={handleSuggest} />}
          {askedQuestion && (
            <div className="flex w-full max-w-3xl flex-col gap-y-4">
              {messageHistory.map((message, index) =>
                message.who === 'server' ? (
                  <ServerMessage
                    key={index}
                    id={message.id}
                    conversationId={conversationId}
                    message={message.message || null}
                    finished={message.finished}
                    isNewBuyer={isNewBuyer}
                  />
                ) : (
                  <ClientMessage
                    key={index}
                    message={message.message}
                    ref={index === messageHistory.length - 2 ? bottomRef : null}
                  />
                ),
              )}
            </div>
          )}
          {askedQuestion && (
            <div className="w-full max-w-xl space-y-4">
              {isClosed && <NetworkError />}
              {AskPrompt}
              {!isLoadingUser && isNewBuyer && allowAsksumoDiscount && (
                <UnlockBanner />
              )}
            </div>
          )}

          <Disclaimer />
        </div>
      </div>
      <ChatPageFooter />
    </>
  );
}
